import Image from 'next/legacy/image';
import Link from 'next/link';

function Logo({
  logoURL, link, width, height, beta,
}: {logoURL: string, link: string, width: number, height: number, beta?: boolean}) {
  return (
    <div className="is-flex is-flex-direction-row is-align-items-flex-end">
      <Link href={link} legacyBehavior>
        <div
          style={{ width, height }}
        >
          <Image src={logoURL} alt="Logo" width={width} height={height} />
        </div>
      </Link>
      {beta && (
      <div style={{
        marginBottom: '3em',
        marginLeft: '-1.5em',
        zIndex: 2,
      }}
      >
        <span className="tag is-primary has-text-weight-semibold" style={{ borderRadius: '99px', fontSize: '0.6em' }}>BETA</span>
      </div>
      )}
    </div>
  );
}

Logo.defaultProps = {
  beta: false,
};

export default Logo;
