import Link from 'next/link';
import { useRouter } from 'next/router';

import { Text, Typography } from '@/sharedComponents/v1/Components/Typography';
import Logo from '@/sharedComponents/Logo';
import LoginSignupForm from '@/components/LoginSignupForm';

import { LOGO_WITH_TEXT_URL, PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '@/constants';
import { styFadeInFromBottom } from '@/styles/AnimationStyle';
import { styLoginContainer } from './style';

function LoginSection() {
  const router = useRouter();

  return (
    <div css={[styLoginContainer, styFadeInFromBottom]}>
      <Logo logoURL={LOGO_WITH_TEXT_URL} link="/" width={156} height={40} />
      <Typography body="display-sm-semibold" type="main" margin="28px 0 6px 0">
        Login to access your account
      </Typography>
      <Typography margin="0 0 42px 0">
        By logging in, you agree to our
        <a href={TERMS_OF_SERVICE_LINK} target="_blank" rel="noreferrer">
          <Text
            body="text-sm-semibold"
            type="link"
          >
            {' '}
            Terms of Service
            {' '}
          </Text>
        </a>
        and
        <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
          <Text
            body="text-sm-semibold"
            type="link"
          >
            {' '}
            Privacy Policy
            {' '}
          </Text>
        </a>
      </Typography>

      <LoginSignupForm login redirect />

      <Typography margin="12px 0">
        Don&apos;t have an account yet?
        {' '}
        <Link
          href={{
            pathname: '/signup',
            query: router.query,
          }}
        >
          Sign up for free
        </Link>
      </Typography>
    </div>
  );
}

export default LoginSection;
