import { css } from '@emotion/react';

export const styLoginContainer = css({
  display: 'flex',
  backgroundColor: 'var(--background)',
  flexDirection: 'column',
  justifyContent: 'center',
  aligItems: 'flex-start',
  width: '100%',
  maxWidth: '400px',
  padding: '40px 0',
});
